const data = JSON.stringify({
  total_pages: 5,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Síntomas Típicos',
      description: 'Indique si ha tenido los siguientes síntomas durante las últimas 24 horas y su gravedad:',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Orinar frecuentemente con pequeños volúmenes en cada micción (ha notado un incremento en la frecuencia para ir a orinar)",
          answers: [
            {
              id: 1,
              desc: "hasta 4 veces por dia",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "5-6 vecesldia",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "7-8 vecesldia",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "9-10 o más veces/día",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Ganas de orinar (tuvo una fuerte necesidad de orinar que es difícil de controlar)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Dolor ardiente al orinar",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Sensación de vaciado incompleto de la vejiga (para dejar de orinar, sientes que no has orinado del todo y quieres volver a orinar)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Sensación de dolor en la parte inferior del abdomen, no relacionada con la micción (región/parte inferior del abdomen)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Se ve sangre en la orina (que no es la menstruación)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Diferenciales',
      description: 'Indique si ha tenido los siguientes síntomas durante las últimas 24 horas y su gravedad:',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Dolor en el área del flanco (en uno o ambos lados/en el área lumbar)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Secreción vaginal anormal (secreción) (en cantidad, color y/u olor)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Secreción uretral (secreción) (agujero por donde se orina) sin orinar",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Fiebre/temperatura corporal elevada (especificar si se midió)",
          answers: [
            {
              id: 1,
              desc: "≤37.5 °C",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 °C",
              name: "Sí, leve",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 °C",
              name: "Sí, moderado",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 °C",
              name: "Sí, severo",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Calidad de Vida',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Por favor, indique cuanto malestar ha experimentado usted debido a sus síntomas en las últimas 24 horas (Marque solamente la respuesta que se ajuste mejor)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No tengo ningun malestar (Ningùn sfntoma en absoluto. Me siento tan bien como siempre)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Tengo un poco de malestar (Me siento algo peor de lo habitual)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Siento un malestar moderado (Me siento bastante mal)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Siento un malestar severo (Me siento terriblemente mal)",
              points: 3
            }
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Por favor, indique cómo sus sintomas han interferido con sus actividades y trabajo diarios en las últimas 24 horas (Marque solamente Ja respuesta que se ajuste mejor)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No interfirieron para nada (Trabajé /o habitua/ para un dfa /aboral)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "lnterfirieron levemente (Presenté ciertas molestias para et trabajo diario)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "lnterfirieron moderadamente (EI trabajo diario requirió esfuerzo)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "lnterfirieron severamente/grave- mente (EI trabajo o las actividades habituales fueron casi imposibles)",
              points: 3
            }
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Por favor, indique cómo sus sintomas han interferido con sus actividades sociales (visitar a las amistades, reunirse con sus amigos, etc.) en las últimas 24 horas (Marque solamente la respuesta que se aiuste meior)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No interfirieron para nada (Fui capaz de disfrutar de mis actividades sociales normales)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "lnterfirieron levemente (Realice menos actividades de lo usual)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "lnterfirieron moderadamente (Tuve que pasar mucho tiempo en casa)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "lnterfirieron severamente (Los sintomas impidieron que saliese de mi casa)",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Adicional',
      description: 'Indique si dispone de lo siguiente en el momento de cumplimentar este cuestionario:',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "¿ Menstruación (Perfodo menstrual) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Sí",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "¿ Sfndrome premenstrual? (lntensos dolores en vientre bajo irradiados a la espalda baia) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Sí",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "¿ Sintomas de sindrome menopausico (por ejemplo, por ejemplo, sensación repentina de: calor en el rostro y parte superior del cuerpo, sudor, mareo o sofocos) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Sí",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "¿ Embarazo ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Sí",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "¿ Diabetes mellitus (diagnosticada médicamente) (azúcar/glucemia elevada) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Sí",
              points: "yes"
            }
          ]
        }
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
